@import 'mixins';
#downloadIssue {
  background-color: rgb(250,250,250);
}
#viewerLogin, #blurBackground {
  background-color: rgb(128, 128, 128);
}
#article {
  border: 1px solid #000000;
}
#mobileMenu{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_Mobile_Menu_Active.png) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Hover.png) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Click.png) !important;
    }
  }
}
#mobileArticle{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_Mobile_Menu_Active.png) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Hover.png) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Click.png) !important;
    }
  }
}
#article{
  .taskbar_btn{
    display:block;
    width: 26px;
    height: 26px;
    padding:0;
    background-size: 100% auto;
  }
  .article_button{
    width: 26px;
    height: 26px;
    display: block;
    background-size: 100% auto;
  }
  #previousArticle{
    &.disabled{
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Disabled.png) !important;
      }
    }
    &:hover{
      .arrow_button{
        background-image:url(../images/Webviewer_Bigarrow_Back_hover.png) !important;
      }
    }
    &:active{
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Click.png) !important;
      }
    }
    .arrow_button{
      background-image:url(../images/Webviewer_Bigarrow_Back_active.png) !important;
    }
  }
  #nextArticle{
    &.disabled{
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Disabled.png) !important;
      }
    }
    &:hover{
      .arrow_button{
        background-image:url(../images/Webviewer_Bigarrow_Forward_hover.png) !important;
      }
    }
    &:active{
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Click.png) !important;
      }
    }
    .arrow_button{
      background-image:url(../images/Webviewer_Bigarrow_Forward_active.png) !important;
    }
  }

  #previousArticleTop{
    &.disabled{
      .article_button{
        background-image:url(../../images/WebViewer_BackOne_Active.png) !important;
      }
    }
    &:hover{
      .article_button{
        background-image:url(../../images/Webviewer_BackOne_Hover.png) !important;
      }
    }
    &:active{
      .article_button{
        background-image:url(../../images/WebViewer_BackOne_Click.png) !important;
      }
    }
    .article_button{
      background-image:url(../../images/WebViewer_BackOne_Active.png) !important;
    }
  }
  #nextArticleTop{
    &.disabled{
      .article_button{
        background-image:url(../../images/WebViewer_ForwardOne_Active.png) !important;
      }
    }
    &:hover{
      .article_button{
        background-image:url(../../images/WebViewer_ForwardOne_Hover.png) !important;
      }
    }
    &:active{
      .article_button{
        background-image:url(../../images/Webviewer_ForwardOne_Click.png) !important;
      }
    }
    .article_button{
      background-image:url(../../images/WebViewer_ForwardOne_Active.png) !important;
    }
  }
  #articlePrintPage{
    &:hover{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Printer_Hover.png) !important;
      }
    }
    &:active{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Printer_Click.png) !important;
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_Printer_Active.png) !important;
    }
  }
  #articleClose{
    &:hover{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_BackToPrint_Hover.png) !important;
      }
    }
    &:active{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_BackToPrint_Click.png) !important;
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_BackToPrint_Active.png) !important;
    }
  }
  #articleFont{
    &.disabled{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Active.png) !important;
      }
    }
    &:hover{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Hover.png) !important;
      }
    }
    &:active{
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Click.png) !important;
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_Textsize_Active.png) !important;
    }
  }
  #articleFonts{
    button{
      &:hover{
        .article_font_small{
          background-image:url(../../images/Webviewer_Textview_Smalltext_Hover.png) !important;
        }
        .article_font_default{
          background-image:url(../../images/Webviewer_Textview_Mediumtext_Hover.png) !important;
        }
        .article_font_large{
          background-image:url(../../images/Webviewer_Textview_Largetext_Hover.png) !important;
        }
      }
      &:active{
        .article_font_small{
          background-image:url(../../images/Webviewer_Textview_Smalltext_Click.png) !important;
        }
        .article_font_default{
          background-image:url(../../images/Webviewer_Textview_Mediumtext_Click.png) !important;
        }
        .article_font_large{
          background-image:url(../../images/Webviewer_Textview_Largetext_Click.png) !important;
        }
      }
      .article_font_small{
        background-image:url(../../images/Webviewer_Textview_Smalltext_Active.png) !important;
      }
      .article_font_default{
        background-image:url(../../images/Webviewer_Textview_Mediumtext_Active.png) !important;
      }
      .article_font_large{
        background-image:url(../../images/Webviewer_Textview_Largetext_Active.png) !important;
      }
    }
  }
}
/* First page button */
#firstPage{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_GoTo_Start_Active.png) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Hover.png) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Click.png) !important;
    }
  }
  &.disabled{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Disabled.png) !important;
    }
  }
}
#previousPage{
  .taskbar_btn {
    background-image:url(../../images/WebViewer_BackOne_Active.png) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_BackOne_Hover.png) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/WebViewer_BackOne_Click.png) !important;
    }
  }
  &.disabled{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_BackOne_Disabled.png) !important;
    }
  }
}
#pagesInfo{

}
#nextPage{
  .taskbar_btn {
    background-image:url(../../images/WebViewer_ForwardOne_Active.png) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/WebViewer_ForwardOne_Hover.png) !important;
    }
    &.disabled{
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ForwardOne_Click.png) !important;
    }
  }
  &.disabled{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ForwardOne_Disabled.png) !important;
    }
  }
}
/* Last page */
#lastPage{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_GoTo_End_Active.png?6) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_Hover.png?6) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_Click.png?6) !important;
    }
  }
  &.disabled{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_disabled.png) !important;
    }
  }
}

#zoomOut{
  .taskbar_btn{
    background-image:url(../../images/Webviewer_Topbar_minus.png) !important;
  }
}
#zoomIn{
  .taskbar_btn{
    background-image:url(../../images/Webviewer_Topbar_plus.png) !important;
  }
}
#fullScreen{
  &.fullscreen{
    .taskbar_btn {
      background-image:url(../../images/Webviewer_ResizeDown_Active.png) !important;
    }
    &:hover{
      .taskbar_btn{
        background-image:url(../../images/Webviewer_ResizeDown_Hover.png) !important;
      }
    }
    &:active{
      .taskbar_btn{
        background-image:url(../../images/Webviewer_ResizeDown_Click.png) !important;
      }
    }
  }
  .taskbar_btn {
    background-image:url(../../images/Webviewer_ResizeUp_Active.png?1) !important;
  }
  &:hover{
    .taskbar_btn{
      background-image:url(../../images/WebViewer_ResizeUp_Hover.png) !important;
    }
  }
  &:active{
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ResizeUp_Click.png) !important;
    }
  }
}

#View{
  #ArrowLeft{
    &.disabled{
      .arrows_btn_prev{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Disabled.png) !important;
      }
    }
    &:hover{
      .arrows_btn_prev{
        background-image:url(../images/Webviewer_Bigarrow_Back_hover.png) !important;
      }
    }
    &:active{
      .arrows_btn_prev{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Click.png) !important;
      }
    }
    .arrows_btn_prev{
      background-image:url(../images/Webviewer_Bigarrow_Back_active.png) !important;
    }
  }
  #ArrowRight{
    &.disabled{
      .arrows_btn_next{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Disabled.png) !important;
      }
    }
    &:hover{
      .arrows_btn_next{
        background-image:url(../images/Webviewer_Bigarrow_Forward_hover.png) !important;
      }
    }
    &:active{
      .arrows_btn_next{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Click.png) !important;
      }
    }
    .arrows_btn_next{
      background-image:url(../images/Webviewer_Bigarrow_Forward_active.png) !important;
    }
  }
}

/* Left menu style */
#LeftMenu{
  .left_nav_button[disabled="disabled"]{
    .articles{
      background-image:url(../images/Webviewer_LeftNav_ArticleList_disabled.png) !important;
      &:hover{
        background-image:url(../images/Webviewer_LeftNav_ArticleList_disabled.png) !important;
      }
    }
    .search{
      background-image:url(../images/Webviewer_LeftNav_Search_disabled.png) !important;
      &:hover{
        background-image:url(../images/Webviewer_LeftNav_Search_disabled.png) !important;
      }
    }
    .issues{
      background-image:url(../images/Webviewer_LeftNav_IssueList_disabled.png) !important;
      &:hover{
        background-image:url(../images/Webviewer_LeftNav_IssueList_disabled.png) !important;
      }
    }
    .pages{
      background-image:url(../images/Webviewer_LeftNav_Pagelist_disabled.png) !important;
      &:hover{
        background-image:url(../images/Webviewer_LeftNav_Pagelist_disabled.png) !important;
      }
    }
    .download{
      background-image:url(../images/Webviewer_LeftNav_Download_disabled.png) !important;
      &:hover{
        background-image:url(../images/Webviewer_LeftNav_Download_disabled.png) !important;
      }
    }
  }
  .articles{
    background-image:url(../images/Webviewer_LeftNav_ArticleList_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_ArticleList_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_ArticleList_Click.png) !important;
    }
  }
  .search{
    background-image:url(../images/Webviewer_LeftNav_Search_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_Search_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Search_Click.png) !important;
    }
  }
  .issues{
    background-image:url(../../images/Webviewer_LeftNav_IssueList_Active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_IssueList_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_IssueList_Click.png) !important;
    }
  }
  .pages{
    background-image:url(../images/Webviewer_LeftNav_Pagelist_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_Pagelist_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Pagelist_Click.png) !important;
    }
  }
  .download{
    background-image:url(../images/Webviewer_LeftNav_Download_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav__Download_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Download_Click.png) !important;
    }
  }
  .login{
    background-image:url(../images/Webviewer_LeftNav_Login_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_Login_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Login_Click.png) !important;
    }
  }
  .logout{
    background-image:url(../images/Webviewer_LeftNav_logout_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_logout_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Logout_Click.png) !important;
    }
  }
  #extend{
    background-image:url(../images/Webviewer_LeftNav_expand_active.png) !important;
    &:hover{
      background-image:url(../images/Webviewer_LeftNav_expand_hover.png) !important;
    }
    &:active{
      background-image:url(../../images/Webviewer_LeftNav_Expand_Click.png) !important;
    }
  }
}